<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{path:'/'}"><b>首页</b></el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item>住宿</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>

    <el-col :span="24" class="warp-main">
      <el-col :span="24" class="toolbar" style="padding-bottom: 0px;">
        <el-form :inline="true" :model="filters">
          <el-form-item>
            <el-select v-model="filters.type" placeholder="请选择住宿类型">
								<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" >
								</el-option>
							</el-select>
          </el-form-item>
          <el-form-item>
            <el-input v-model="filters.name" placeholder="请输入名称" clearable></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="getAccommodationListPage" icon="search">查询</el-button>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" v-on:click="goReset" icon="search">重置</el-button>
          </el-form-item>
          <el-form-item >
            <el-button type="primary" icon="plus" v-if="permissionButton.add" @click="$router.push({path:'/accommodation/add'})">新增</el-button>
          </el-form-item>
        </el-form>
      </el-col>

      <el-table :data="accommodation" highlight-current-row v-loading="listLoading" @selection-change="selsChange" style="width: 100%;" border>
        <el-table-column type="selection" width="50" align="center"></el-table-column>
        <el-table-column type="index" label="序号" width="60" align="center"></el-table-column>
        <el-table-column prop="name" label="名称" min-width="300"></el-table-column>
        <el-table-column prop="imageUrl" label="列表缩略图片" width="130" align="center">
          <template slot-scope="scope" >
            <img v-if="scope.row.indexImageUrl" style="width: 89px; height: 50px" :src="downloadFileURL + scope.row.indexImageUrl+'/'"/>
          </template>
        </el-table-column>
        <el-table-column prop="readNumber" label="阅读次数" width="120" align="center" sortable></el-table-column>
        <el-table-column prop="likeCount" label="点赞人数" width="120" align="center" sortable></el-table-column>
        <el-table-column prop="isTop" label="置顶" width="80" align="center" sortable>
          <template slot-scope="scope">
            <span v-bind:class="{ temporary: scope.row.isTop == '是' }">{{scope.row.isTop}}</span>
          </template>
        </el-table-column>
          <el-table-column prop="recommended" label="推荐" width="80" align="center" sortable>
            <template slot-scope="scope">
              <span v-bind:class="{ temporary: scope.row.recommended == '是' }">{{scope.row.recommended}}</span>
            </template>
          </el-table-column>
        <el-table-column prop="status" label="状态" width="80" align="center" sortable sortable>
        <template slot-scope="scope">
          <span v-bind:class="{ temporary: scope.row.status == 1 }">{{ scope.row.status == 0 ? "已发布" : "草稿"}}</span>
        </template>
        </el-table-column>
        <el-table-column prop="publisher" label="发布人" width="100" align="center"></el-table-column>
        <el-table-column prop="formatUpdateTime" label="修改时间" width="180" align="center" sortable>
            <template slot-scope="{row}">{{ row.formatUpdateTime || '暂无更改' }}</template>
        </el-table-column>
        <el-table-column prop="formatCreateTime" label="创建时间" width="180" align="center" sortable>
        </el-table-column>
        <el-table-column label="操作" width="220" align="center" fixed="right">
          <template slot-scope="scope">
            <el-button size="small" v-if="permissionButton.view"  @click="$router.push({path:'/accommodation/view',query:{id:scope.row.id}})">查看</el-button>
            <el-button size="small" type="primary" v-if="permissionButton.edit"  @click="$router.push({path:'/accommodation/edit',query:{id:scope.row.id}})">编辑</el-button>
            <el-button type="danger" @click="delAccommodation(scope.$index,scope.row)" v-if="permissionButton.del" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-col :span="24" class="table-footer">
        <el-button type="danger" size="small" @click="batchDeleteAccommodation" :disabled="this.sels.length===0" v-if="permissionButton.batchDel">批量删除</el-button>
        <el-pagination
          @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :page-sizes="[10,15,20,25,50]" :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" style="float:right;">
        </el-pagination>
      </el-col>
    </el-col>
  </el-row>
</template>
<script>
  import { downloadFileURL,} from "../../../api/system-api";
  import {reqGetAccommodationListPage,reqDeleteAccommodation,reqBatchDeleteAccommodation}  from '../../../api/accommodation-api'
  import permission from '../../../common/permission';
  export default{
    data(){
      return{
        filters:{
          name:'',
          type:''
        },
        options: [
				{
					value: '0',
					label: '酒店',
				},
				{
					value: '1',
					label: '民宿',
				},
				{
					value: '2',
					label: '青旅',
				},
			],
        downloadFileURL:downloadFileURL,
        columnsTypeArray:[],
        accommodation:[],
        total:0,
        pageNum:1,
        pageSize:15,
        listLoading:false,
        sels:[],
        permissionButton:new permission.PermissionButton,
      }
    },
    methods:{
      handleCurrentChange(val) {
        this.pageNum = val;
        this.getAccommodationListPage()
      },
      //控制分页每页显示条数
      handleSizeChange(val) {
        console.log(`每页 ${val} 条`);
        this.pageSize=val;
        this.getAccommodationListPage();
      },
      //查询
      getAccommodationListPage(){
        let para = {
          pageNum: this.pageNum,
          pageSize: this.pageSize,
          name: this.filters.name,
          type:this.filters.type
        };
        this.listLoading = true;
        reqGetAccommodationListPage(para).then((res) => {
          this.total = res.data.data.total;
          this.accommodation = res.data.data.records;
          this.listLoading = false;
        })
      },
      selsChange: function (sels) {
        this.sels = sels;
      },
      formatType:function (row) {
        var type='';
        this.typeArray.forEach(function (obj) {
          if(row.type==obj.value){
            return type=obj.text;
          }
        });
        return type;
      },
      
      formatUploadTime:function(row){
        if(row.uploadTime){
          return util.formatDate.format(new Date(row.uploadTime),'yyyy-MM-dd')
        }
      },
      //重置
      goReset(){
          this.filters.name='';
           this.filters.type='';
          this.pageNum=1;
          this.getAccommodationListPage();
      },
        // 删除
      delAccommodation: function(index, row) {
        this.$confirm('确认删除该记录吗?', '提示', {type: 'warning'}).then(() => {
          this.listLoading = true;
          let para = {id: row.id};
          reqDeleteAccommodation(para).then((res) => {
           if(res.data.code===1){
             this.listLoading = false;
             this.$message({
               message: '删除成功',
               type: 'success'
             });
             this.getAccommodationListPage();
           }else {
             this.listLoading = false;
             this.$message({
               message: '删除失败',
               type: 'error'
             });
            this.getAccommodationListPage();
           }
          });
        }).catch(() => {
        });
      },
         // 批量删除
      batchDeleteAccommodation: function () {
        var ids = this.sels.map(item => item.id).toString();
        this.$confirm('确认删除选中记录吗？', '提示', {
          type: 'warning'
        }).then(() => {
          this.listLoading = true;
          let para = {ids: ids};
          reqBatchDeleteAccommodation(para).then((res) => {
           if(res.data.code===1){
             this.listLoading = false;
             this.$message({
               message: '删除成功',
               type: 'success'
             });
             this.getAccommodationListPage();
           }else {
             this.listLoading = false;
             this.$message({
               message: '删除失败',
               type: 'error'
             });
            this.getAccommodationListPage();
           }
          });
        }).catch(() => {

        });
      },

    },mounted(){
      this.getAccommodationListPage();
      var permissionId = sessionStorage.getItem("permissionId");
      permission.getPermissionButton(permissionId,this.permissionButton);
    }
  }
</script>
